import HBBusManager from "./hbBusManager";

const DaVinciService = (merchantDetail, paramLocation) => {
  // Register HBBusManager firstly.
  const { sendEventRequest } = HBBusManager();

  const clickItem = payload => sendEventRequest("mpc_clickItem", payload);
  const viewItem = payload => sendEventRequest("mpc_viewItem", payload);

  const urlpostfix = merchantDetail?.urlPostfix;
  const merchantId = merchantDetail?.id;

  const women_entrepreneur = merchantDetail?.tagList?.some(i =>
    ["woman-entrepreneur", "woman-entrepreneur-logo-biography"].includes(i)
  )
    ? "yes"
    : "no";
  const location = paramLocation;

  return {
    merchantInfoEvent: () =>
      clickItem({
        buttonType: "forward",
        itemName: "Satıcı bilgileri",
        women_entrepreneur,
        location,
        urlpostfix,
        merchantId
      }),
    tabChangeEvent: tabName =>
      clickItem({
        buttonType: "tab",
        itemName: `${tabName} tab`,
        location: location,
        women_entrepreneur,
        urlpostfix,
        merchantId
      }),
    followEvent: (coupon, isCollected, locationVal, sku) =>
      clickItem({
        buttonType: "forward",
        itemName: "Takip Et",
        location: locationVal ? locationVal : location,
        women_entrepreneur,
        coupon_win: isCollected ? "yes" : "no",
        coupon_start_date: coupon?.startDate ? coupon?.startDate : "",
        coupon_end_date: coupon?.endDate ? coupon?.endDate : "",
        urlpostfix,
        couponId: coupon?.couponId ? coupon?.couponId : "",
        couponName: coupon?.couponName ? coupon?.couponName : "",
        merchantId,
        sku
      }),
    followAndWinEvent: (coupon, isCollected, isFollowOnly = false, locationVal, sku) =>
      clickItem({
        buttonType: "forward",
        itemName: isFollowOnly ? "Takip Et" : "Takip et, kazan",
        location: locationVal ? locationVal : location,
        women_entrepreneur,
        coupon_win: isCollected ? "yes" : "no",
        coupon_start_date: coupon?.startDate ? coupon?.startDate : "",
        coupon_end_date: coupon?.endDate ? coupon?.endDate : "",
        urlpostfix,
        couponId: coupon?.couponId ? coupon?.couponId : "",
        couponName: coupon?.couponName ? coupon?.couponName : "",
        merchantId,
        sku
      }),
    unfollowEvent: (locationVal, coupon) =>
      clickItem({
        buttonType: "forward",
        itemName: "Takibi bırak",
        location: locationVal ? locationVal : location,
        women_entrepreneur,
        urlpostfix,
        couponId: coupon?.couponId ? coupon?.couponId : "",
        couponName: coupon?.couponName ? coupon?.couponName : "",
        merchantId
      }),
    clickHoverRatingEvent: () =>
      clickItem({
        buttonType: "forward",
        itemName: "Satıcı puanı",
        location,
        women_entrepreneur,
        urlpostfix,
        merchantId
      }),
    clickHoverMerchantTagEvent: (itemName, partners) =>
      clickItem({
        buttonType: "forward",
        itemName: partners !== null ? "Partner satıcı" : itemName,
        location,
        women_entrepreneur,
        urlpostfix,
        merchantId,
        brand:
          partners?.map(partner => ({
            brandId: partner?.brandId,
            brandName: partner?.productBrandName,
            brandType: partner?.partnerShipType === "AuthorizedDealer" ? "Yetkili Satıcı" : "Resmi Satıcı"
          })) || []
      }),
    viewMerchantTagEvent: (itemName, partners) =>
      viewItem({
        itemName: partners !== null ? "Partner satıcı" : itemName,
        location,
        women_entrepreneur,
        urlpostfix,
        merchantId,
        brand:
          partners?.map(partner => ({
            brandId: partner?.brandId,
            brandName: partner?.productBrandName,
            brandType: partner?.partnerShipType === "AuthorizedDealer" ? "Yetkili Satıcı" : "Resmi Satıcı"
          })) || []
      }),
    // Click rating calculation button in tooltip
    clickRatingCalculationButtonEvent: () =>
      clickItem({
        buttonType: "forward",
        itemName: "Puan hesaplama detayları",
        location,
        women_entrepreneur,
        urlpostfix,
        merchantId
      }),
    clickMerchantInformationButtonEvent: () =>
      clickItem({
        buttonType: "forward",
        itemName: "Satıcı bilgileri",
        location,
        women_entrepreneur,
        urlpostfix,
        merchantId
      }),
    searchInStoreEvent: (searchText, paramWomenEntrepreneur, paramUrlpostfix) =>
      clickItem({
        buttonType: "forward",
        itemName: "mağazada ara",
        location,
        women_entrepreneur: paramWomenEntrepreneur ? paramWomenEntrepreneur : women_entrepreneur,
        urlpostfix: urlpostfix ? urlpostfix : paramUrlpostfix,
        value: searchText,
        merchantId
      }),
    initialLoadingEvent: coupon =>
      viewItem({
        itemName: "kupon-firsati",
        location,
        urlpostfix,
        couponId: coupon?.couponId ? coupon?.couponId : "",
        couponName: coupon?.couponName ? coupon?.couponName : "",
        merchantId
      }),
    additionalInfoEvent: itemName =>
      viewItem({
        itemName,
        location,
        women_entrepreneur,
        urlpostfix,
        merchantId
      }),
    closeCouponEvent: () =>
      clickItem({
        buttonType: "forward",
        itemName: "Kupon fırsatı modal kapat",
        location,
        women_entrepreneur,
        urlpostfix,
        merchantId
      }),
    viewCouponsPageEvent: () =>
      clickItem({
        buttonType: "forward",
        itemName: "Kuponlarım success yönlendirme",
        location,
        women_entrepreneur,
        urlpostfix,
        merchantId
      }),
    viewProductRowEvent: (row, rowOrder) => {
      return viewItem({
        itemName: "productRows",
        keyword: row?.data?.length.toString(),
        placement_title: row?.name || "",
        position: rowOrder?.toString(),
        products:
          row?.data?.map(({ productId, variantList }, index) => {
            const isDefaultVariant = variantList.find(variant => variant.isDefault);
            return {
              position: (index + 1).toString(),
              product_id: productId.toString(),
              listingId: isDefaultVariant.listing.listingId.toString(),
              sku: isDefaultVariant.sku.toString(),
              price: {
                discountRate: isDefaultVariant.listing.priceInfo.discountRate.toString(),
                finalPrice: isDefaultVariant.listing.priceInfo.originalPrice.toString(),
                finalPriceOnSale: isDefaultVariant.listing.priceInfo.price.toString()
              }
            };
          }) || [],
        location,
        urlpostfix,
        merchantId
      });
    },
    clickProductRowEvent: (row, rowOrder, prodIndex) => {
      return clickItem({
        buttonType: "forward",
        itemName: "productRows",
        keyword: row?.data?.length.toString(),
        placement_title: row?.name || "",
        position: rowOrder.toString(),
        products:
          [
            row?.data?.map(({ productId, variantList }, index) => {
              const isDefaultVariant = variantList.find(variant => variant.isDefault);
              return {
                position: (index + 1).toString(),
                product_id: productId.toString(),
                listingId: isDefaultVariant.listing.listingId.toString(),
                sku: isDefaultVariant.sku.toString(),
                price: {
                  discountRate: isDefaultVariant.listing.priceInfo.discountRate.toString(),
                  finalPrice: isDefaultVariant.listing.priceInfo.originalPrice.toString(),
                  finalPriceOnSale: isDefaultVariant.listing.priceInfo.price.toString()
                }
              };
            })[prodIndex]
          ] || [],
        location,
        urlpostfix,
        merchantId
      });
    },
    clickToSeeAllEvent: (row, rowOrder) => {
      return clickItem({
        buttonType: "forward",
        itemName: "productRows tümünü gör",
        keyword: row?.data?.length.toString(),
        placement_title: row?.name || "",
        position: rowOrder.toString(),
        products:
          row?.data?.map(({ productId, variantList }, index) => {
            const isDefaultVariant = variantList.find(variant => variant.isDefault);
            return {
              position: (index + 1).toString(),
              product_id: productId.toString(),
              listingId: isDefaultVariant.listing.listingId.toString(),
              sku: isDefaultVariant.sku.toString(),
              price: {
                discountRate: isDefaultVariant.listing.priceInfo.discountRate.toString(),
                finalPrice: isDefaultVariant.listing.priceInfo.originalPrice.toString(),
                finalPriceOnSale: isDefaultVariant.listing.priceInfo.price.toString()
              }
            };
          }) || [],
        location,
        urlpostfix,
        merchantId
      });
    },
    viewBannerRowEvent: (row, rowOrder) => {
      return viewItem({
        itemName: "custom banner row",
        keyword: row?.data?.length.toString(),
        position: rowOrder?.toString(),
        banners:
          row?.data?.map((banner, index) => ({
            placement_title: row?.component?.title || "",
            position: (index + 1).toString(),
            banner_link: banner?.url || "",
            sku: banner?.sku
          })) || [],
        location,
        urlpostfix,
        merchantId
      });
    },
    viewCouponRowEvent: (row, rowOrder) => {
      return viewItem({
        itemName: "coupon component row",
        position: rowOrder?.toString(),
        location,
        urlpostfix,
        merchantId
      });
    },
    viewCampaignRowEvent: (row, rowOrder) => {
      return viewItem({
        itemName: "campaign component row",
        position: rowOrder?.toString(),
        location,
        urlpostfix,
        merchantId
      });
    },
    viewVisualCommentRowEvent: (row, rowOrder) => {
      return viewItem({
        itemName: "visual comments component",
        keyword: row?.data?.length.toString(),
        position: rowOrder?.toString(),
        sortType: row.sortingType,
        comments:
          row?.data?.map(comment => ({
            commentId: comment.id,
            reviewContent: comment.review.content,
            reviewRating: comment.star,
            product: {
              sku: comment.product.sku,
              rating: comment.product.average,
              reviewCount: comment.product.userContentCount
            },
            media: comment.media?.map(img => img.url) || [],
            video: comment.video?.map(video => video.videoUrl) || []
          })) || [],
        location,
        urlpostfix,
        merchantId
      });
    },
    clickVisualCommentRowItemEvent: (row, rowOrder, comment, commentOrder) => {
      return clickItem({
        itemName: "visual comments component",
        keyword: row?.data?.length.toString(),
        position: rowOrder?.toString(),
        sortType: row.sortingType,
        comments: [
          {
            commentId: comment.id,
            reviewContent: comment.review.content,
            reviewRating: comment.star,
            product: {
              sku: comment.product.sku,
              rating: comment.product.average,
              reviewCount: comment.product.userContentCount
            },
            media: comment.media?.map(img => img.url) || [],
            video: comment.video?.map(video => video.videoUrl) || [],
            position: commentOrder
          }
        ],
        location,
        urlpostfix,
        merchantId
      });
    },
    clickVisualCommentModalGoToProductButtonEvent: (row, rowOrder, comment, commentOrder) => {
      return clickItem({
        itemName: "visual comments component - product detail",
        keyword: row?.data?.length.toString(),
        position: rowOrder?.toString(),
        sortType: row.sortingType,
        comments: [
          {
            commentId: comment.id,
            reviewContent: comment.review.content,
            reviewRating: comment.star,
            product: {
              sku: comment.product.sku,
              rating: comment.product.average,
              reviewCount: comment.product.userContentCount
            },
            media: comment.media?.map(img => img.url) || [],
            video: comment.video?.map(video => video.videoUrl) || [],
            position: commentOrder
          }
        ],
        location,
        urlpostfix,
        merchantId
      });
    },
    clickBannerRowEvent: (row, rowOrder, bannerIndex) => {
      return clickItem({
        buttonType: "forward",
        itemName: "custom banner row",
        keyword: row?.data?.length.toString(),
        position: rowOrder.toString(),
        banners: [
          row?.data?.map(banner => ({
            placement_title: banner?.bannerTitle || "",
            position: (bannerIndex + 1).toString(),
            banner_link: banner?.url || "",
            sku: banner?.sku
          }))[bannerIndex]
        ],
        location,
        urlpostfix,
        merchantId
      });
    },
    clickCustomBannerProductTagEvent: (row, rowOrder, bannerIndex, prodIndex) => {
      return clickItem({
        itemName: "customizable banner product",
        keyword: row?.data?.length.toString(),
        position: rowOrder?.toString(),
        banners: [
          row?.data?.map(banner => ({
            position: (bannerIndex + 1).toString(),
            products:
              banner?.tags?.map((tag, index) => {
                const findedProduct = banner?.products?.find(product =>
                  product.variantList?.some(variant => variant.sku === tag.sku)
                );
                const { productId, variantList } = findedProduct || {};
                const isDefaultVariant = variantList?.find(variant => variant.isDefault);
                return {
                  position: (index + 1).toString(),
                  product_id: productId.toString(),
                  listingId: isDefaultVariant.listing.listingId.toString(),
                  sku: isDefaultVariant?.sku?.toString(),
                  price: {
                    discountRate: isDefaultVariant?.listing?.priceInfo?.discountRate?.toString(),
                    finalPrice: isDefaultVariant?.listing?.priceInfo?.originalPrice?.toString(),
                    finalPriceOnSale: isDefaultVariant?.listing?.priceInfo?.price?.toString()
                  }
                };
              })[prodIndex] || []
          }))[bannerIndex]
        ],
        location,
        urlpostfix,
        merchantId
      });
    },
    viewCustomBannerProductTagEvent: (row, rowOrder, bannerIndex, prodIndex) => {
      return viewItem({
        itemName: "customizable banner product",
        keyword: row?.data?.length.toString(),
        position: rowOrder?.toString(),
        banners: [
          row?.data?.map(banner => ({
            position: (bannerIndex + 1).toString(),
            products:
              banner?.tags?.map((tag, index) => {
                const findedProduct = banner?.products?.find(product =>
                  product.variantList?.some(variant => variant.sku === tag.sku)
                );
                const { productId, variantList } = findedProduct || {};
                const isDefaultVariant = variantList?.find(variant => variant.isDefault);
                return {
                  position: (index + 1).toString(),
                  product_id: productId.toString(),
                  listingId: isDefaultVariant.listing.listingId.toString(),
                  sku: isDefaultVariant?.sku?.toString(),
                  price: {
                    discountRate: isDefaultVariant?.listing?.priceInfo?.discountRate?.toString(),
                    finalPrice: isDefaultVariant?.listing?.priceInfo?.originalPrice?.toString(),
                    finalPriceOnSale: isDefaultVariant?.listing?.priceInfo?.price?.toString()
                  }
                };
              })[prodIndex] || []
          }))[bannerIndex]
        ],
        urlpostfix,
        merchantId,
        location
      });
    },
    viewCustomBannerRowEvent: (row, rowOrder) => {
      return viewItem({
        itemName: "customizable banner",
        keyword: row?.data?.length.toString(),
        position: rowOrder?.toString(),
        banners:
          row?.data?.map((banner, index) => ({
            title: banner?.title || "",
            subtitle: banner?.subTitle || "",
            position: (index + 1).toString(),
            banner_link: banner?.url || "",
            products:
              banner?.tags?.map((tag, index) => {
                const findedProduct = banner?.products?.find(product =>
                  product.variantList?.some(variant => variant.sku === tag.sku)
                );
                const { productId, variantList } = findedProduct || {};
                const isDefaultVariant = variantList?.find(variant => variant.isDefault);
                return {
                  position: (index + 1).toString(),
                  product_id: productId.toString(),
                  listingId: isDefaultVariant.listing.listingId.toString(),
                  sku: isDefaultVariant?.sku?.toString(),
                  price: {
                    discountRate: isDefaultVariant?.listing?.priceInfo?.discountRate?.toString(),
                    finalPrice: isDefaultVariant?.listing?.priceInfo?.originalPrice?.toString(),
                    finalPriceOnSale: isDefaultVariant?.listing?.priceInfo?.price?.toString()
                  }
                };
              }) || []
          })) || [],
        location,
        urlpostfix,
        merchantId
      });
    },
    clickCustomBannerRowEvent: (row, rowOrder, bannerIndex) => {
      return clickItem({
        itemName: "customizable banner",
        keyword: row?.data?.length.toString(),
        position: rowOrder?.toString(),
        banners: [
          row?.data?.map(banner => ({
            title: banner?.title || "",
            subtitle: banner?.subTitle || "",
            position: (bannerIndex + 1).toString(),
            banner_link: banner?.url || ""
          }))[bannerIndex]
        ],
        location,
        urlpostfix,
        merchantId
      });
    },
    viewVideoBannerRowEvent: (row, rowOrder) => {
      return viewItem({
        itemName: "video component",
        keyword: row?.data?.length.toString(),
        position: rowOrder?.toString(),
        bannerId: row?.id,
        videos:
          row?.data?.map((banner, index) => ({
            videoId: banner?.id,
            title: banner?.title,
            link: banner?.url || "",
            products:
              banner?.products?.map(({ productId, variantList }, index) => {
                const isDefaultVariant = variantList.find(variant => variant.isDefault);
                return {
                  listingId: isDefaultVariant?.listing.listingId.toString(),
                  position: (index + 1).toString(),
                  price: {
                    discountRate: isDefaultVariant?.listing.priceInfo.discountRate.toString(),
                    finalPrice: isDefaultVariant?.listing.priceInfo.originalPrice.toString(),
                    finalPriceOnSale: isDefaultVariant?.listing.priceInfo.price.toString()
                  },
                  product_id: productId.toString(),
                  sku: isDefaultVariant?.sku.toString()
                };
              }) || [],
            position: (index + 1).toString()
          })) || [],
        location,
        urlpostfix,
        merchantId
      });
    },
    clickVideoBannerRowEvent: (row, rowOrder, bannerIndex) => {
      return clickItem({
        itemName: "video component",
        keyword: row?.data?.length.toString(),
        position: rowOrder.toString(),
        bannerId: row?.id,
        videos: [
          row?.data?.map(banner => ({
            position: (bannerIndex + 1).toString(),
            videoId: banner?.id,
            title: banner?.title,
            link: banner?.url || "",
            products:
              banner?.products?.map(({ productId, variantList }, index) => {
                const isDefaultVariant = variantList.find(variant => variant.isDefault);
                return {
                  listingId: isDefaultVariant?.listing.listingId.toString(),
                  position: (index + 1).toString(),
                  price: {
                    discountRate: isDefaultVariant?.listing.priceInfo.discountRate.toString(),
                    finalPrice: isDefaultVariant?.listing.priceInfo.originalPrice.toString(),
                    finalPriceOnSale: isDefaultVariant?.listing.priceInfo.price.toString()
                  },
                  product_id: productId.toString(),
                  sku: isDefaultVariant?.sku.toString()
                };
              }) || []
          }))[bannerIndex]
        ],
        location,
        urlpostfix,
        merchantId
      });
    },
    clickVideoProductTagEvent: (row, rowOrder, bannerIndex, prodIndex) => {
      return clickItem({
        itemName: "video component product",
        keyword: row?.data?.length.toString(),
        bannerId: row?.id,
        videos: [
          row?.data?.map(banner => ({
            position: (bannerIndex + 1).toString(),
            videoId: banner?.id,
            title: banner?.title,
            link: banner?.url,
            products:
              [
                banner?.products?.map(({ productId, variantList }, index) => {
                  const isDefaultVariant = variantList.find(variant => variant.isDefault);
                  return {
                    listingId: isDefaultVariant?.listing.listingId.toString(),
                    position: (index + 1).toString(),
                    price: {
                      discountRate: isDefaultVariant?.listing.priceInfo.discountRate.toString(),
                      finalPrice: isDefaultVariant?.listing.priceInfo.originalPrice.toString(),
                      finalPriceOnSale: isDefaultVariant?.listing.priceInfo.price.toString()
                    },
                    product_id: productId.toString(),
                    sku: isDefaultVariant?.sku.toString()
                  };
                })[prodIndex]
              ] || []
          }))[bannerIndex]
        ],
        merchantId,
        urlpostfix,
        location
      });
    },
    viewTemplateBannerRowEvent: (row, rowOrder) => {
      return viewItem({
        itemName: "template banner",
        keyword: row?.data?.length.toString(),
        position: rowOrder?.toString(),
        location,
        urlpostfix,
        merchantId,
        banners:
          row?.data?.map((banner, index) => ({
            position: (index + 1).toString(),
            banner_link: banner?.url || ""
          })) || []
      });
    },
    clickTemplateBannerRowEvent: (row, rowOrder, bannerIndex) => {
      return clickItem({
        itemName: "template banner",
        keyword: row?.data?.length.toString(),
        position: rowOrder?.toString(),
        location,
        urlpostfix,
        merchantId,
        banners: [
          row?.data?.map(banner => ({
            position: (bannerIndex + 1).toString(),
            banner_link: banner?.url || ""
          }))[bannerIndex]
        ]
      });
    }
  };
};

export default DaVinciService;
